import React,{useState,createContext,useEffect} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import NavBar from './components/NavBar/NavBar';
import Site from './components/Site/Site';

export const CartContext=createContext()
const CART_KEY="react-shop";


function App() {
  const [cart,setCart]=useState({});
  useEffect(()=>{
    const cartFromStorage=localStorage.getItem(CART_KEY);
    if (cartFromStorage !== null){
      setCart(JSON.parse(cartFromStorage))
    }
  },[])

  useEffect(()=>{
    localStorage.setItem(CART_KEY,JSON.stringify(cart))
  },[cart])

  const addToCart=(item)=>{
    // console.log("item",item)
    if(!cart[item.id]){
      cart[item.id]=item;
      cart[item.id].quantity=1
    }else{
      cart[item.id].quantity+=1
    }
    setCart({...cart})
    console.log("cart",cart)
  }

  const countQuantity=(e)=>{
    let total=0
    // console.log(Object.keys(cart))
    Object.keys(cart).map(key=> (total +=cart[key].quantity))
    return total
  }

  const cartQuantity=()=>{
    let total=0
    Object.keys(cart).map(key=>(
        total+=cart[key].quantity*cart[key].prix
        ))
        return total
  }

  const removeFromCart=(item)=>{
        if(cart[item.id].quantity !==1){
            cart[item.id].quantity -=1
        }else{
         delete cart[item.id]
        }
        setCart({...cart})
        console.log("cart",cart)
  }

  const emptyCart=()=>{
    const reponse=window.confirm("Etes-vous sur vouloir vider le caddie ?")
     if(reponse){
      setCart({})
     }
  }

  const contextValue={cart,addToCart,countQuantity,removeFromCart,emptyCart,cartQuantity}

  return (
    <Router>
      <CartContext.Provider value={contextValue}>
      <NavBar/>
      <Site/>
      </CartContext.Provider>
    </Router>
  );
}

export default App;


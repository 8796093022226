import React,{useContext} from 'react';
import {CartContext} from '../../App';
import { NavLink } from 'react-router-dom';



function CartDetaill() {
    
 const {cart,removeFromCart,addToCart,emptyCart,cartQuantity}=useContext(CartContext)


    return (
        <>
        <button className="btn btn-primary m-4" onClick={emptyCart}>Vider le caddie</button>
            <table className="table table-sm">
                <thead>
                    <tr>
                        <th className="text-center" scope="col">Quantité</th>
                        <th className="text-center" scope="col">Produit</th>
                        <th className="text-center" scope="col">Prix unitaire</th>
                        <th className="text-center" scope="col">Prix total</th>
                        <th className="text-center" colSpan="2">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(cart).map((key,index) => {
                        return (
                            <tr key={index}>
                                <th className="text-center" scope="row">{cart[key].quantity}</th>
                                <td className="text-center">{cart[key].produit}</td>
                                <td className="text-center">{cart[key].prix}€</td>
                                <td className="text-center">{cart[key].quantity * cart[key].prix}€</td>
                                <td className="text-center"><button className="btn btn-danger" onClick={()=>removeFromCart(cart[key])}>-</button></td>
                                <td className="text-center"><button className="btn btn-primary" onClick={()=>addToCart(cart[key])}>+</button></td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <h3 className=""> Total : {cartQuantity()}€</h3>
           <NavLink to="/payment" > <button className="btn btn-outline-dark">Passer la command</button></NavLink>
            
        </>
    )
}

export default CartDetaill;

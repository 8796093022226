import React,{useContext} from 'react';
import { CartContext } from '../../App';
import { NavLink } from 'react-router-dom';
import './Produit.css'

const Produit=({data})=>{

    // console.log(props)
    const {addToCart}=useContext(CartContext)
    return (
        
            // <div className="card shadow h-100 text-center p-0 mb-0 m-0">
            //   <NavLink  to={`/produits/${data.id}`}>
            //      <img src={data.img} className="card-img-top  d-block mx-auto h-100 w-100" alt={data.produit} />
            //   </NavLink>
            //     <div className="card-body">
            //         <h5 className="card-title mb-0">{data.produit.substring(0,12)}...</h5>
            //         <p className="card-text lead fw-bold">{data.prix}€</p>
            //         <button className="btn btn-outline-dark" onClick={() => addToCart(data)}>Ajouter au panier</button>
            //     </div>
            // </div>
        <div className="card shadow text-center p-0 mb-3 m-0">
            <NavLink to={`/produits/${data.id}`}>
                <img src={data.img} className="card-img-top image-height d-block mx-auto w-100" alt={data.produit} />
            </NavLink>
            <div className="card-body">
                <h5 className="card-title mb-0">{data.produit.substring(0, 12)}...</h5>
                <p className="card-text lead fw-bold">{data.prix}€</p>
                <button className="btn btn-outline-dark" onClick={() => addToCart(data)}>Ajouter au panier</button>
            </div>
        </div>


    )
}

export default Produit

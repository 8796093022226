import React,{useContext} from 'react';
import {CartContext} from '../../App';
// import ReactDOM from "react-dom";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
// import PayPalButton from './PaypalButton'


function PaypalPaement() {

    // const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });
    // const {cart,removeFromCart,addToCart,emptyCart,cartQuantity}=useContext(CartContext)
    const {cartQuantity}=useContext(CartContext)

 const createOrder=(data, actions)=>{
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: cartQuantity(),
            currency: "EUR",
          },
        },
      ],
    });
  }

 const onApprove=(data, actions)=>{
    return actions.order.capture().then((details)=>{
        console.log(details)
        alert(details.payer.name.given_name + ' ' + details.payer.name.surname + ', votre transaction est effectuée. Vous allez recevoir une notification très bientôt lorsque nous validons votre paiement.');
    });
  }

  

    return (
      <div className="container my-5">
        <PayPalScriptProvider options={{ "client-id": "YOUR_CLIENT_ID" }}>
          <PayPalButtons
            createOrder={createOrder}
            onApprove={onApprove}
          />
        </PayPalScriptProvider>
      </div>
    )
}

export default PaypalPaement;

import React from 'react';
import {Route, Routes} from 'react-router-dom';
import CartDetaill from '../CartDetail/CartDetaill';
import CategorieProduit from '../CategorieProduit/CategorieProduit';
import PaypalPaement from '../PaypalPaement/PaypalPaement';
import ProduitDetail from '../ProduitDetail/ProduitDetail';
import Produits from '../Produits/Produits'; 




function Site() {
    return (
        <Routes>
            <Route exact path="/produits/:id" element={<ProduitDetail/>} />
            <Route exact path="/" element={<Produits/>} />
            <Route exact path="/categories/:id" element={<CategorieProduit/>} />
            <Route exact path="/cart" element={<CartDetaill/>} />
            <Route exact path="/payment" element={<PaypalPaement/>} />
        </Routes>
    )
}

export default Site;

import React,{useState,useEffect} from 'react';
// import Banniere from '../Produits/Banniere/Banniere';
import Produit from '../Produit/Produit';
import { NavLink,useParams } from 'react-router-dom';
import Categories from '../Categories/Categories';

function CategorieProduit() {
    const [categorieProduit, setCategorieProduit] = useState([])
    const {id}=useParams();

    useEffect(() => {
        fetch(`https://apiecom.leydjialerte224.com/api/categories/${id}`, {
            'method': 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(reponse => {
                return reponse.json()
            })
            .then(reponse => {
                return setCategorieProduit(reponse)
            })
            .catch(error => {
                return console.log(error)
            })
    }, [categorieProduit])
    return (
        <>
        {/* <Banniere /> */}
            <div className="container my-5 py-5">
                <div className="row justify-content-center">
                    <div className="col-12 mb-5">
                        <h1 className="display-6 fw-bolder text-center">Latest Produits</h1>
                        <hr />
                    </div>
                </div>
                <div className="row justify-conent-center">
                    <div className="button d-flex justify-content-center mb-5 pb-5">
                        <NavLink to="/" className="btn btn-outline-dark me-2">All</NavLink>
                        <Categories/>

                    </div>
                    {categorieProduit.produits && categorieProduit.produits.map(item=>{
                        return(
                            <div key={item.id} className="col-md-3 mb-2">
                               <Produit data={item} />
                            </div>
                        )
                    })}



                </div>


            </div>

            
        </>
    )
}

export default CategorieProduit

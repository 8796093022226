// import React,{useState,useEffect} from "react";
// import {NavLink } from "react-router-dom";
// import Categories from "../Categories/Categories";
// import Produit from "../Produit/Produit";
// // import Banniere from './Banniere/Banniere';



// export default function Products(props) {

//   const [produits,setProduits]=useState([])

  
//   useEffect(()=>{
//     fetch('http://127.0.0.1:8000/api/produits/',{
//         'method':'GET',
//         headers:{
//           'Content-Type':'application/json',
//         }
//       })
//       .then(reponse=>{
//         return reponse.json()
//        })
//        .then(reponse=>{
//         return setProduits(reponse)
//        })
//        .catch(error=>{
//         return console.log(error)
//        })
//    },[])
  

//   return (
//     <>
//       {/* <Banniere /> */}
//       <div className="container my-5 py-5">
//         <div className="row justify-content-center">
//           <div className="col-md-6 mb-5">
//             <h1 className="display-6 fw-bolder text-center">KaliyaShop</h1>
//             <hr/>
//           </div>
//         </div>
//         <div className="row justify-conent-center">
//         <div className="button d-flex justify-content-center mb-5 pb-5">
//               <NavLink to="/" className="btn btn-outline-dark me-2">All</NavLink>
//               <Categories/>
//             </div>
//         {produits && produits.map((item) => {
//           return (
//             <div key={item.id} className="col-12 col-md-3 mb-2 p-1">
//                 <Produit data={item}/>
//             </div>
//           )
//         })}



//         </div>
            

//       </div>
//     </>
//   );
// }

// ---------------------------------------------------------------------

import React,{useState,useEffect} from "react";
import {NavLink } from "react-router-dom";
import * as bootstrap from 'bootstrap';
import Categories from "../Categories/Categories";
import Produit from "../Produit/Produit";
import './Produits.css'
// import image1 from '../../../public/images/fond1.jpg'
// import Banniere from './Banniere/Banniere';



export default function Products(props) {

  const [produits,setProduits]=useState([])

  
  useEffect(()=>{
    fetch('https://apiecom.leydjialerte224.com/api/produits/',{
        'method':'GET',
        headers:{
          'Content-Type':'application/json',
        }
      })
      .then(reponse=>{
        return reponse.json()
       })
       .then(reponse=>{
        return setProduits(reponse)
       })
       .catch(error=>{
        return console.log(error)
       })
   },[])

   useEffect(() => {
    var myCarousel = document.querySelector('#myCarousel')
    new bootstrap.Carousel(myCarousel, {
      interval: 8000,
      wrap: true
    })
  }, [])
  

  return (
    <>
      <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
        <ol className="carousel-indicators">
          <li data-bs-target="#myCarousel" data-bs-slide-to="0" className="active"></li>
          <li data-bs-target="#myCarousel" data-bs-slide-to="1"></li>
          <li data-bs-target="#myCarousel" data-bs-slide-to="2"></li>
        </ol>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src='/images/m6.jpg' className="d-block w-100" alt="..." />
            <div className="carousel-caption d-none d-md-block">
              <h5>First slide label</h5>
              <p>Some representative placeholder content for the first slide.</p>
            </div>
          </div>
          <div className="carousel-item">
            <img src="/images/m3.jpg" className="d-block w-100" alt="..." />
            <div className="carousel-caption d-none d-md-block">
              <h5>Second slide label</h5>
              <p>Some representative placeholder content for the second slide.</p>
            </div>
          </div>
          <div className="carousel-item">
            <img src="/images/m4.jpg" className="d-block w-100" alt="..." />
            <div className="carousel-caption d-none d-md-block">
              <h5>Third slide label</h5>
              <p>Some representative placeholder content for the third slide.</p>
            </div>
          </div>
        </div>
        <a className="carousel-control-prev" href="#myCarousel" role="button" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </a>
        <a className="carousel-control-next" href="#myCarousel" role="button" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </a>
      </div>

      <div className="container my-5 py-5">
        <div className="row justify-content-center">
          <div className="col-md-6 mb-5">
            <h1 className="display-6 fw-bolder text-center">KaliyaShop</h1>
            <hr/>
          </div>
        </div>
        <div className="row justify-conent-center">
        <div className="button d-flex justify-content-center mb-5 pb-5">
              <NavLink to="/" className="btn btn-outline-dark me-2">All</NavLink>
              <Categories/>
            </div>
        {produits && produits.map((item) => {
          return (
            <div key={item.id} className="col-12 col-md-3 mb-2 p-1">
                <Produit data={item}/>
            </div>
          )
        })}



        </div>
            

      </div>
    </>
  );
}
import React,{useContext,useState,useEffect} from 'react';
import {CartContext} from '../../App';
import {NavLink, useParams} from 'react-router-dom';

function ProduitDetail(props) {
    const [produitDetail,setProduitDetail]=useState([])
    const {id} =useParams();
    const {addToCart}=useContext(CartContext)
    // console.log(id)

    useEffect(()=>{
        fetch(`https://apiecom.leydjialerte224.com/api/produits/${id}`,{
            'method':'GET',
            headers:{
              'Content-Type':'application/json',
            }
          })
          .then(reponse=>{
            return reponse.json()
           })
           .then(reponse=>{
            return setProduitDetail(reponse)
           })
           .catch(error=>{
            return console.log(error)
           })
       },[id])

    return (
        <div className="container py-5">
            <div className="row py-4">
                <div className="col-md-6">
                <img src={produitDetail.img} alt={produitDetail.produit} height="400px" width="400px"/>
                </div>
                <div className="col-md-6">
                     {/* <h4 className="text-uppercase text-black-50">
                         {produitDetail.categorie}
                     </h4> */}
                     <h1 className="display-5">{produitDetail.produit}</h1>
                     <p className="lead">
                         Rating 
                     </p>
                     <h3 className="display-6 fw-bold my-4">
                         {produitDetail.prix}€
                     </h3>
                     <p className="lead">{produitDetail.description}</p>
                     <button className="btn btn-outline-dark px-4 py-2" onClick={() => addToCart(produitDetail)}>
                         Ajouter au panier
                     </button>
                     <NavLink to="/cart" className="btn btn-dark ms-2 px-3 py-2">
                         Afficher panier
                     </NavLink>
                </div>
            </div>
        </div>
    )
}

export default ProduitDetail

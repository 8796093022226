import React, { useState, useEffect } from 'react';
// import Banniere from '../Produits/Banniere/Banniere';
// import Produit from '../Produit/Produit';
import { NavLink} from 'react-router-dom';

function Categories() {
    const [categories, setCategories] = useState([])
    

    useEffect(() => {
        fetch('https://apiecom.leydjialerte224.com/api/categories/', {
            'method': 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(reponse => {
                return reponse.json()
            })
            .then(reponse => {
                return setCategories(reponse)
            })
            .catch(error => {
                return console.log(error)
            })
    }, [])

    console.log(categories)

    return (
        <>
            {categories && categories.map(categorie => {
                return <NavLink to={`/categories/${categorie.id}`} key={categorie.id} className="btn btn-outline-dark me-2">{categorie.categorie}</NavLink>
            })}
        </>
    )
}

export default Categories



// import React, { useState, useEffect } from 'react';
// import { NavLink } from 'react-router-dom';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

// function Categories() {
//   const [categories, setCategories] = useState([]);

//   useEffect(() => {
//     fetch('http://127.0.0.1:8000/api/categories/', {
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//     })
//       .then((response) => {
//         return response.json();
//       })
//       .then((data) => {
//         setCategories(data);
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   }, []);

//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 3,
//   };

//   return (
//     <Slider {...settings}>
//       {categories.map((categorie) => {
//         return (
//           <div key={categorie.id}>
//             <NavLink
//               to={`/categories/${categorie.id}`}
//               className="btn btn-outline-dark me-2"
//             >
//               {categorie.categorie}
//             </NavLink>
//           </div>
//         );
//       })}
//     </Slider>
//   );
// }

// export default Categories;

// import React, { useState, useEffect } from 'react';
// import Slider from 'react-slick';
// import { NavLink } from 'react-router-dom';
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";


// function Categories() {
//   const [categories, setCategories] = useState([]);

//   const settings = {
//     dots: false,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 4,
//     slidesToScroll: 1,
//   };

//   useEffect(() => {
//     fetch('http://127.0.0.1:8000/api/categories/', {
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//     })
//       .then((response) => response.json())
//       .then((data) => setCategories(data))
//       .catch((error) => console.log(error));
//   }, []);

//   return (
//     <>
//       <Slider {...settings}>
//         {categories &&
//           categories.map((categorie) => (
//             <NavLink
//               to={`/categories/${categorie.id}`}
//               key={categorie.id}
//               className="btn btn-outline-dark me-2"
//             >
//               {categorie.categorie}
//             </NavLink>
//           ))}
//       </Slider>
//     </>
//   );
// }

// export default Categories;

// import React, { useState, useEffect } from 'react';
// import Slider from 'react-slick';
// import { NavLink } from 'react-router-dom';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import styles from './Categories.module.css'

// function Categories() {
//   const [categories, setCategories] = useState([]);

//   const settings = {
//     dots: false,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 4,
//     slidesToScroll: 1,
//     prevArrow: <div className={styles.prev} />,
//     nextArrow: <div className={styles.next} />
//   };

//   useEffect(() => {
//     fetch('http://127.0.0.1:8000/api/categories/', {
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//     })
//       .then((response) => response.json())
//       .then((data) => setCategories(data))
//       .catch((error) => console.log(error));
//   }, []);

//   return (
//     <>
//       <Slider {...settings}>
//         {categories &&
//           categories.map((categorie) => (
//             <NavLink
//               to={`/categories/${categorie.id}`}
//               key={categorie.id}
//             //   className={styles.btnCat}
//               className="btn btn-outline-dark me-2"
//             >
//               {categorie.categorie}
//             </NavLink>
//           ))}
//       </Slider>
      
//     </>
//   );
// }

// export default Categories;






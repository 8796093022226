import React,{useContext} from 'react';
import {CartContext} from "../../App";
import { useNavigate } from 'react-router-dom';
// import {NavLink} from 'react-router-dom';



function NavBar() {

    const {countQuantity}=useContext(CartContext)
    const navigate = useNavigate();

    return (
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark py-3 shadow-sm">
      <div className="container">
        <button className="btn btn-outline-dark" style={{backgroundColor:"#212529",color:"white",fontSize:"30px"}} onClick={() => navigate('/')}>KaliyaShop</button>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
            <li className="nav-item">
            <button className="btn btn-outline-dark nav-link active" style={{backgroundColor:"#212529",color:"white"}} onClick={() => navigate('/')}>Home</button>
              {/* <a className="nav-link active" aria-current="page" href="/">Home</a> */}
            </li>
            <li className="nav-item">
              {/* <a className="nav-link" href="#">Produits</a> */}
              <button className="btn btn-outline-dark nav-link" style={{backgroundColor:"#212529",color:"white"}} onClick={() => navigate('/')}>Produits</button>
            </li>
            <li className="nav-item">
              {/* <a className="nav-link" href="#">A propos</a> */}
              <button className="btn btn-outline-dark nav-link" style={{backgroundColor:"#212529",color:"white"}} onClick={() => navigate('/')}>A propos</button>

            </li>
            <li className="nav-item">
              {/* <a className="nav-link" href="#">Contact</a> */}
              <button className="btn btn-outline-dark nav-link" style={{backgroundColor:"#212529",color:"white"}} onClick={() => navigate('/')}>Contact</button>

            </li>
          </ul>
          <div className="button">
            <a href="" className="btn btn-outline-light">
              <i className="fa fa-sign-in me-1"></i>Login</a>
              <a href="" className="btn btn-outline-light ms-2">
              <i className="fa fa-user-plus me-1"></i>Register</a>
              <button href="/cart" className="btn btn-outline-light ms-2"  onClick={() => navigate('/cart')}>
              <i className="fa fa-shopping-cart me-1"></i>Panier : {countQuantity()}</button>
          </div>
        </div>
      </div>
    </nav>
    )
}

export default NavBar;
